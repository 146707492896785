import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Text, TextButton } from '@wix/design-system';
import s from './ShowMoreText.module.scss';

type Props = {
  originalText: string | undefined;
  maxChars: number;
  dataHookPrefix?: string;
  textSize?: 'small' | 'medium';
  handleShowMoreClick?: () => void;
  showMoreInitialState?: boolean;
};

const ShowMoreText: FC<Props> = ({
  originalText,
  maxChars,
  dataHookPrefix = '',
  textSize = 'small',
  handleShowMoreClick,
  showMoreInitialState = false,
}) => {
  const { t } = useTranslation();
  const [showingMore, setShowingMore] = useState(showMoreInitialState);
  // TODO: will not update if text changes, must rework if editing required
  const [text, setText] = useState<string>(originalText || '');
  const [moreText, setMoreText] = useState<string>('');

  const spaceIndex = originalText?.indexOf(' ', maxChars);
  useEffect(() => {
    if (spaceIndex && spaceIndex > -1) {
      setText(originalText?.substring(0, spaceIndex) || '');
      setMoreText(originalText?.substring(spaceIndex) || '');
    }
  }, [originalText, spaceIndex]);
  return (
    <>
      <Text
        size={textSize}
        weight="thin"
        style={{ wordBreak: 'break-word' }}
        dataHook={`${dataHookPrefix}show-more-text`}
      >
        {text}
        <span className={moreText && !showingMore ? '' : s.hide}>{`... `}</span>
        <span
          className={moreText && showingMore ? '' : s.hide}
          data-hook={`${dataHookPrefix}show-more-text-extra`}
        >
          {moreText}
        </span>
      </Text>
      {moreText && (
        <>
          <TextButton
            dataHook={`${dataHookPrefix}show-more-text-button`}
            skin="dark"
            underline="always"
            size={textSize}
            weight="thin"
            onClick={() => {
              setShowingMore(true);
              handleShowMoreClick?.();
            }}
            className={showingMore ? s.hide : ''}
          >
            {t('show-more-text-button')}
          </TextButton>
          <TextButton
            dataHook={`${dataHookPrefix}show-less-text-button`}
            skin="dark"
            underline="always"
            size={textSize}
            weight="thin"
            onClick={() => setShowingMore(false)}
            className={!showingMore ? s.hide : ''}
          >
            {t('show-less-text-button')}
          </TextButton>
        </>
      )}
    </>
  );
};

export default ShowMoreText;
